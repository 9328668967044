import { Injectable, Optional, SkipSelf } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { LocalizationConfigService } from './localization-config.service'
import { Util } from '../models/util.model'

/**
 * Class representing the translation service.
 */
@Injectable({
	providedIn: 'root',
})
export class LocalizationService {
	private _localeId: string = 'en_EN'
	private _suffix: string = '.13' // JSON VERSION

	// NB: tenere allineato con initLanguages su session
	private languages = ['en_EN', 'it_IT', 'fr_FR', 'de_DE', 'es_ES', 'pt_PT', 'zh_ZH']

	/**
	 * @constructor
	 * @param {LocalizationService} singleton - the localization service
	 * @param {LocalizationServiceConfig} config - the localization config
	 * @param {TranslateService} translateService - the translate service
	 */
	constructor(
		@Optional() @SkipSelf() private singleton: LocalizationService,
		private config: LocalizationConfigService,
		private translateService: TranslateService
	) {
		if (this.singleton) {
			throw new Error('LocalizationService is already provided by the root module')
		}

		// let lang = localStorage.getItem('NG_TRANSLATE_LANG_KEY');
		// if (lang) {
		//   this._localeId = lang;
		// } else {
		this._localeId = navigator.language

		// console.log(this._localeId)
		// }
		if (this._localeId.includes('-')) {
			this._localeId = Util.replaceAll(navigator.language, '-', '_')
		} else if (this._localeId.includes('_')) {
			if (!this.languages.includes(this._localeId)) {
				this._localeId = 'en_EN'
			}
		} else {
			let upper = this._localeId.toUpperCase()
			this._localeId = this._localeId + '_' + upper

			// console.log(this._localeId)
		}

		if (!this.languages.includes(this._localeId)) {
			this._localeId = 'en_EN'
		}
	}

	/**
	 * Initialize the service.
	 * @returns {Promise<void>}
	 */
	public initService() {
		// : Promise<void>
		// Fallback language if key is missing on json specific file.
		this.translateService.setDefaultLang('en_EN' + this._suffix)

		// for old Fe angularjs
		// var ng_lang = localStorage.getItem('NG_TRANSLATE_LANG_KEY') ? localStorage.getItem('NG_TRANSLATE_LANG_KEY').split('.') : ''
		// if (ng_lang != '') {
		// 	this._localeId = ng_lang[0]
		// }
		// this._localeId = (ng_lang != '' ? ng_lang[0] :  '' ) || this._localeId

		// console.log(this._localeId)

		// voglio usare solo la lingua del browser
		return this.useLanguage(this._localeId)
	}

	/**
	 * change the selected language
	 * @returns {Promise<void>}
	 */
	public useLanguage(lang: string) {
		// : Promise<void>

		let myLang = lang
		if (lang.indexOf(this._suffix) < 0) {
			// 16.05.2022 patch per evitare append ricorsive [ls]
			myLang += this._suffix
		}

		// localStorage.setItem('language_suffix', this._suffix)
		// localStorage.setItem('NG_TRANSLATE_LANG_KEY', lang)

		//this.translateService.setDefaultLang(lang.concat(this._suffix));

		// 20.05.2022 il default e' per il fallBack ? [ls]
		//this.translateService.setDefaultLang(myLang);
		this.translateService.use(myLang)

		// 20.05.2022 perche' forzare la promise ? [ls]
		/*
  return this.translateService.use(myLang)
    .toPromise()
    .catch(() => {
      throw new Error('LocalizationService.init failed');
    }); 
*/
	}

	/**
	 * Gets the instant translated value of a key (or an array of keys).
	 * @param key
	 * @param interpolateParams
	 * @returns {string|any}
	 */
	public translate(key: string | string[], interpolateParams?: object): string {
		return this.translateService.instant(key, interpolateParams) as string
	}

	// 16.05.2022
	getSavedLang() {
		return this._localeId
	}
}
